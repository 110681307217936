import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About.vue'
import Book from '../views/Book.vue'
import Jujitsu from '../views/Jujitsu.vue'
import Dashboard from '../views/Dashboard.vue'

const routes = [
  {
    path: "/",
    name: "Poems",
    component: Dashboard
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/book',
    name: 'book',
    component: Book
  },
  {
    path: '/jujitsu',
    name: 'jujitsu',
    component: Jujitsu
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
