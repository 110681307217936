<template>
  <div
    row
    class="bg-primary"
  >
    <v-card
      :key="project.title"
      text
      v-for="(project, index) in projects"
      row
      flat
      class="bg-primary"
    >
      <v-card-text v-if="index==1">
        <v-row class="bg-primary">
          <v-col
            class="ck-content"
            sm="12"
            md="12"
            lg="10"
            xl="8"
          >
            <hr class="bg-secondary thick" />
            <div
              class="bg-primary pl-5 pt-5 pb-5 text-black"
              v-html="project.content"
            ></div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
    <v-row class="mb-10">
      <v-col
        col="12"
        sm="12"
        md="12"
        lg="10"
        xl="8"
      >
        <v-carousel
          :show-arrows="true"
          progress
          transition="fade-transition"
          hide-delimiter-background
          hide-delimiters
          width="auto"
          contain
          v-model="slider"
        >
          <v-carousel-item
            v-for="(type,i) in types"
            :key="i"
            :src="picurl + type.pic"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>

</template>

<script setup>
import { onMounted, ref } from "vue";
import shareData from "@/composables/share";
import axios from "axios";

const { projects, fetchDocs } = shareData();

let search = ref("");
let defurl = ref("https://angelnicoleworld.com/data.php");
let picurl = ref("https://dash.angelnicoleworld.com/images2/");
let formdata = ref([]);
let types = ref([]);
let slider = ref(0);
let tid = "about";
var redraw = false;

onMounted(async () => {
  formdata = {
    action: "displayab",
    search: search.value,
    tid: "about",
  };
  await fetchDocs(formdata, redraw, defurl);
  showPics();
});

function showPics() {
  var url = "https://angelnicoleworld.com/data.php";
  var formdata = {
    action: "display-bp",
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      types.value = res.data;
    })
    .catch((error) => alert(error));
}
</script>

<style scoped>
.topic-title input {
  color: red !important;
}
.done {
  color: #689f38;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
.about:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
</style>
