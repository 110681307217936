<template>
  <div class="bg-secondary">
    <v-row v-if="showtop">
      <v-col
        cols="3"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        v-for="head in headings"
        :key="head.id"
        class="mt-2 ml-5 pt-3"
      >
        <v-btn
          :class="isActive == head.sort ? 'active' : 'inActive'"
          size="x-large"
          variant="text"
          color="accent"
          class="chap-titles btn-font text-subtitle-2 text-xl-h4 text text-lg-h5 text-md-h6 text-sm-subtitle-1"
          @click="chngChap(head.heading, head.sort)"
        >
          {{ head.heading }}
          <v-tooltip
            activator="parent"
            location="top"
          >Change Chapter</v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-if="!dispdoc"
      class="mt-5 hero"
    >
      <v-col
        sm="12"
        md="12"
        lg="12"
        xl="4"
        class="ml-10 mb-5 mr-3"
        text
      >
        <div
          class="ml-3"
          v-for="(poem, index) in poems"
          :key="poem.id"
        >
          <p
            class="poem poem-titles ml-10 black--text"
            @click="editTxt(poem.id)"
            v-html="poem.title"
          ></p>
          <v-btn
            v-if="index == poems.length-1"
            href="https://www.amazon.com/L-V-Living-Valuing-Existence/dp/B0CRLBDPYS/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
            target="_blank"
            size="x-large"
            variant="text"
            color="accent"
            class="amaz ml-6"
          >
            More Poems ...
          </v-btn>
        </div>
      </v-col>
    </div>
    <div
      :key="refresh"
      v-if="dispdoc"
      row
      class="bg-primary"
    >
      <v-card
        v-if="dispdoc"
        row
        flat
        class="bg-bg-primary"
      >
        <v-card-text>
          <v-row
            dense
            class="bg-primary mb-5"
          >
            <v-col
              class
              sm="12"
              md="10"
              lg="9"
              xl="8"
            >
              <h1
                class="mt-2 text-accent text-center"
                v-html="curdoc.title"
              ></h1>
            </v-col>
            <v-col
              class="text-accent text-center"
              sm="12"
              md="10"
              lg="9"
              xl="8"
            >
              <v-btn
                size="x-large"
                variant="text"
                color="black"
                min-width="0"
                width="60px"
                @click="nextPg(curdoc.sort)"
              >
                <v-icon
                  size="30"
                  left
                >mdi-skip-forward</v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >Next Poem</v-tooltip>
              </v-btn>
              <v-btn
                size="30"
                variant="text"
                color="black"
                min-width="0"
                width="60px"
                @click="prevPg(curdoc.sort)"
              >
                <v-icon
                  size="30"
                  left
                >mdi-skip-backward</v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >Previous Poem</v-tooltip>
              </v-btn>
              <v-btn
                size="30"
                variant="text"
                color="black"
                min-width="0"
                width="60px"
                @click="(dispdoc = false), (showtop = true)"
              >
                <v-icon
                  size="40"
                  left
                >mdi-close</v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >Close Poem</v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="bg-secondary mt-2">
            <v-col
              class="ck-content"
              sm="12"
              md="10"
              lg="9"
              xl="8"
            >
              <div
                class="bg-secondary pb-5 black--text"
                v-html="curdoc.content"
              ></div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
  
<script setup>
import { onMounted, ref } from "vue";
import shareData from "@/composables/share";

const {
  scrollToTop,
  projects,
  fetchDocs,
  total,
  headings,
  poems,
  getPoems,
  getHeads,
} = shareData();

let search = ref("");
let curdoc = ref([]);
let defurl = ref("https://angelnicoleworld.com/data.php");
let refresh = ref(0);
let dispdoc = ref(false);
let showtop = ref(true);
let formdata = ref([]);
let isActive = ref(0);

var redraw = false;

onMounted(async () => {
  formdata = {
    action: "display",
    search: search.value,
  };
  await fetchDocs(formdata, redraw, defurl);

  formdata = {
    action: "dishead",
    search: "",
  };
  await getHeads(formdata, defurl);

  formdata = {
    action: "dispoem",
    search: headings.value[0].heading,
  };
  await getPoems(formdata, defurl);
});

function editTxt(id) {
  for (var i = 0; i < poems.value.length; i++) {
    if (poems.value[i].id === id) {
      curdoc.value.title = poems.value[i].title;
      poems.value[i].title = "<b>" + poems.value[i].title + "</b>";
      curdoc.value.sort = poems.value[i].sort;
      curdoc.value.content = poems.value[i].content;
      if (curdoc.value.content) {
        dispdoc.value = true;
        showtop.value = false;
      }
    }
  }
  scrollToTop();
}

function chngChap(chap, sort) {
  isActive.value = sort;
  search.value = chap;
  formdata = {
    action: "dispoem",
    search: search.value,
  };
  getPoems(formdata, defurl);
  formdata = {
    action: "dishead",
    search: "",
  };
  getHeads(formdata, defurl);
}

function nextPg(sort) {
  if (sort == poems.value.length - 1) {
    dispdoc.value = false;
    showtop.value = true;
  }
  for (var i = 0; i < poems.value.length - 1; i++) {
    if (poems.value[i].sort === sort) {
      curdoc.value.title = poems.value[i + 1].title;
      poems.value[i + 1].title = "<b>" + poems.value[i + 1].title + "</b>";
      curdoc.value.sort = poems.value[i + 1].sort;
      curdoc.value.content = poems.value[i + 1].content;
      if (curdoc.value.content) {
        dispdoc.value = true;
        showtop.value = false;
      }
    }
    forceRerender();
  }
}

function prevPg(sort) {
  if (sort == 0) {
    dispdoc.value = false;
    showtop.value = true;
  } else {
    for (var i = 0; i <= poems.value.length - 1; i++) {
      if (poems.value[i].sort === sort) {
        curdoc.value.title = poems.value[i - 1].title;
        poems.value[i - 1].title = "<b>" + poems.value[i - 1].title + "</b>";
        curdoc.value.sort = poems.value[i - 1].sort;
        curdoc.value.content = poems.value[i - 1].content;
        if (curdoc.value.content) {
          dispdoc.value = true;
          showtop.value = false;
        }
      }
    }
    forceRerender();
  }
}

function forceRerender() {
  refresh.value += 1;
}
</script>
  
<style>
.amaz:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
.hero {
  background: url("../assets/back3.jpg");
  background-size: cover;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.active {
  font-weight: bold !important;
}
.inActive {
  font-weight: normal !important;
}
.dash {
  border-radius: 25px;
}
hr {
  width: 100%;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid blue;
  border-radius: 2px;
}

.poem-titles {
  font-size: 1.4rem;
  line-height: 2.2;
}

.chap-titles {
  font-size: 1.5rem;
}

[v-cloak] {
  display: none;
}

.done {
  color: #689f38;
}

.column_wrapper {
  float: left;
}

.localp {
  font-weight: 400;
  line-height: 18px;
  font-size: 13px;
  color: #4d4d4d;
  margin: 0;
}

img.line {
  width: 300px;
}

hr.thick {
  border: 2px solid;
}

.edge {
  border-left: double black;
}
.poem:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
.poem:visited {
  background-color: blue;
  white-space: normal;
  width: auto;
}
</style>
  