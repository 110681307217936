import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#9CCC65",
    success: "#000000",
    secondary: "#F1F8E9",
    accent: "#B71C1C",
    info: "#6085B2",
    error: "#F83E70",
    'surface-variant': '#000000',
    'on-surface-variant': '#FFFFFF',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
})