<template>
  <div
    row
    class="bg-primary"
  >
    <v-card
      :key="project.title"
      text
      v-for="(project, index) in projects"
      row
      flat
      class="bg-primary"
    >
      <v-card-text v-if="index==0">
        <v-row class="bg-primary">
          <v-col
            sm="12"
            md="12"
            lg="10"
            xl="6"
          >
            <h1 class="mt-2 text-accent">{{ project.title }}</h1>
          </v-col>
        </v-row>
        <v-row class="bg-secondary">
          <v-col
            class="ck-content"
            sm="12"
            md="12"
            lg="10"
            xl="8"
          >
            <hr class="bg-secondary thick" />
            <div
              class="bg-secondary pl-5 pt-5 pb-5 text-black"
              v-html="project.content"
            ></div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import shareData from "@/composables/share";

const { projects, fetchDocs } = shareData();

let search = ref("");
let defurl = ref("https://angelnicoleworld.com/data.php");
let formdata = ref([]);
var redraw = false;

onMounted(async () => {
  formdata = {
    action: "displayab",
    search: search.value,
    tid: "about",
  };
  await fetchDocs(formdata, redraw, defurl);
});
</script>

<style scoped>
.topic-title input {
  color: red !important;
}
.done {
  color: #689f38;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
.about:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
</style>
