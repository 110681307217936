import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";

loadFonts()

createApp(App)
    .use(router)
    .use(vuetify)
    .use(CKEditor)
    .use(VueAxios, axios)
    .use(VueGtag, {
        appName: 'Angel Nicole World',
        pageTrackerScreenviewEnabled: true,
        config: { id: "G-DCJ1P4Z155" }
    })
    .mount('#app')
