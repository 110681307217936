<template>
  <nav>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="bg-primary pt-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-5"
        >
          <v-avatar
            size="220"
            class="rounded-pill"
            image="https://angel.vsgpartners.com/images/sunflower.jpg"
          >
          </v-avatar>
        </v-col>
      </v-row>
      <div id="nav">
        <ul>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-pen </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/"
            >
              Poems</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-information </v-icon>
            <router-link
              class="accent--text text-h5 nav-font"
              to="/about"
            >
              About</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-book-open </v-icon>
            <router-link
              class="accent--text text-h5 nav-font"
              to="/book"
            >
              The Book</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-ticket </v-icon>
            <router-link
              class="accent--text text-h5 nav-font"
              to="/jujitsu"
            >
              Jujitsu</router-link>
          </li>
        </ul>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      text
      class="bg-secondary"
      app
    >
      <v-icon
        size="50"
        color="black"
        class="ml-5"
        @click="drawer = !drawer"
      >mdi-menu</v-icon>
      <v-toolbar>
        <v-toolbar-title class="truncated pt-3 pb-3 text-accent text-subtitle-2 text-xl-h4 text text-lg-h5 text-md-h6 text-sm-subtitle-1 bg-secondary">L.O.V.E. (Living Openly, Valuing Existence)
        </v-toolbar-title>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-btn
        size="x-large"
        variant="text"
        min-width="0"
        width="60px"
        href="https://www.instagram.com/angelnicoleworld/?hl=en"
        target="_blank"
      >
        <v-icon
          color="red"
          size="30"
        >mdi-instagram</v-icon>
        <v-tooltip
          activator="parent"
          location="bottom"
        >Instagram</v-tooltip>
      </v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
    };
  },
};
</script>

<style scoped>
.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner {
  font-family: "Montserrat", sans-serif !important;
}
ul {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 16px;
  list-style-type: none;
}

li {
  margin-bottom: 10px;
}

a {
  color: #263238 !important;
}

#nav a.router-link-exact-active {
  color: #b71c1c !important;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: #e53935 !important;
  font-family: "Montserrat", sans-serif !important;
}

.home {
  cursor: pointer;
}
</style>
